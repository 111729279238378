@import "@/styles/_variables.scss";



















.empty-layout {
  width: 100%;
}
